#block-editor {

    .testimonial {

        padding: 10px;
        border: 1px solid #062c79;
        border-radius: 6px;

        .block-options {
            display: none;
        }

        p {
            padding: 10px;
            margin-bottom: 5px;
        }

        .author-image {
            padding: 10px;
        }
    }

    .hero-block {
        padding: 10px;

        .tox .tox-editor-container {
            opacity: 0.9;
        }
    }


    .carousel-image {
        padding: 10px;

        .tox .tox-editor-container {
            opacity: 0.9;

        }

        input[type=url] {
            width: 100%;
            padding: 5px;
            opacity: 0.9;

        }

    }

    .divider-block {
        padding: 10px;
    }

    .iframe-block {

        .i-frame {
            background-image: url('/images/2024-icons/iframe-placeholder.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid #062c79;
            border-radius: 6px;

            iframe {
                width: 100%;
                border-radius: 6px;
            }
        }

        input {
            padding: 5px;
        }

    }
}







