.hide{
    display: none;
}

.alignfull {
    margin-left  : calc( -100vw / 2 + 100% / 2 );
    margin-right : calc( -100vw / 2 + 100% / 2 );
    max-width    : 100vw;
}

.alignfull img {
    width: 100vw;
}
