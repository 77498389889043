.hide {
  display: none;
}

.alignfull {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  max-width: 100vw;
}

.alignfull img {
  width: 100vw;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin input[type=email] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type=password] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.jumbotron {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 6rem 2rem;
  position: relative;
}
.jumbotron hr {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.form-control:focus {
  box-shadow: none;
  background-color: #fff;
}

.btn.btn-lg {
  line-height: 1.3;
}

.product-image-selected img {
  max-width: 100%;
  height: auto;
}

.product-images-thumbs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 24px 0;
  padding: 24px 0 0 0;
  list-style: none;
}
.product-images-thumbs li {
  width: 17%;
  margin-left: 1%;
}
.product-images-thumbs li:first-child {
  margin-left: 0;
}
.product-images-thumbs li img {
  max-width: 100%;
  height: auto;
}
.product-images-thumbs li a {
  transition: opacity 0.25s ease;
}
.product-images-thumbs li a:hover, .product-images-thumbs li a.active {
  opacity: 0.55;
}

.update-qty-btn {
  border: 0;
  background-color: transparent;
  padding: 0;
  transition: opacity 0.25s ease;
}
.update-qty-btn:hover {
  opacity: 0.65;
}
.update-qty-btn svg {
  width: 20px;
  height: 20px;
  margin-left: 6px;
}

.qty-cell form {
  display: flex;
  flex-direction: row;
}

.cart-qty {
  width: 70px;
}

#block-editor .testimonial {
  padding: 10px;
  border: 1px solid #062c79;
  border-radius: 6px;
}
#block-editor .testimonial .block-options {
  display: none;
}
#block-editor .testimonial p {
  padding: 10px;
  margin-bottom: 5px;
}
#block-editor .testimonial .author-image {
  padding: 10px;
}
#block-editor .hero-block {
  padding: 10px;
}
#block-editor .hero-block .tox .tox-editor-container {
  opacity: 0.9;
}
#block-editor .carousel-image {
  padding: 10px;
}
#block-editor .carousel-image .tox .tox-editor-container {
  opacity: 0.9;
}
#block-editor .carousel-image input[type=url] {
  width: 100%;
  padding: 5px;
  opacity: 0.9;
}
#block-editor .divider-block {
  padding: 10px;
}
#block-editor .iframe-block .i-frame {
  background-image: url("/images/2024-icons/iframe-placeholder.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #062c79;
  border-radius: 6px;
}
#block-editor .iframe-block .i-frame iframe {
  width: 100%;
  border-radius: 6px;
}
#block-editor .iframe-block input {
  padding: 5px;
}